@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&amp;display=swap";
#page-topbar {
  z-index: 1002;
  background-color: var(--bs-header-bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.navbar-header {
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 0 auto;
  padding: 0 12px 0 0;
  display: flex;
}

.navbar-header .dropdown .show.header-item {
  background-color: var(--bs-tertiary-bg);
}

.navbar-brand-box {
  text-align: center;
  width: 250px;
  padding: 0 1.5rem;
}

.logo {
  line-height: 70px;
}

.logo .logo-sm, .logo-light {
  display: none;
}

.app-search {
  padding: 16px 0;
}

.app-search .form-control {
  background-color: var(--bs-topbar-search-bg);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  border-radius: 30px;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
}

.app-search span {
  z-index: 10;
  color: var(--bs-secondary-color);
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 13px;
}

.megamenu-list li {
  padding: 5px 0;
  position: relative;
}

.megamenu-list li a {
  color: var(--bs-body-color);
}

@media (width <= 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo span.logo-lg {
    display: none;
  }

  .logo span.logo-sm {
    display: inline-block;
  }
}

.page-content {
  padding: 94px 18px 60px;
}

.header-item {
  color: var(--bs-header-item-color);
  border: 0;
  border-radius: 0;
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.header-item:hover {
  color: var(--bs-header-item-color);
}

.header-profile-user {
  background-color: var(--bs-tertiary-bg);
  width: 36px;
  height: 36px;
  padding: 3px;
}

.noti-icon i {
  color: var(--bs-header-item-color);
  font-size: 22px;
}

.noti-icon .badge {
  position: absolute;
  top: 12px;
  right: 4px;
}

.notification-item .d-flex {
  padding: .75rem 1rem;
}

.notification-item .d-flex:hover {
  background-color: var(--bs-tertiary-bg);
}

.dropdown-icon-item {
  text-align: center;
  color: var(--bs-secondary-color);
  border: 1px solid #0000;
  border-radius: 3px;
  padding: 15px 0 9px;
  line-height: 34px;
  display: block;
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.dropdown-icon-item:hover {
  border-color: var(--bs-border-color);
}

.fullscreen-enable [data-bs-toggle="fullscreen"] .bx-fullscreen:before {
  content: "";
}

body[data-topbar="dark"] #page-topbar {
  background-color: var(--bs-header-dark-bg);
}

body[data-topbar="dark"] .navbar-header .dropdown .show.header-item {
  background-color: #ffffff0d;
}

body[data-topbar="dark"] .navbar-header .waves-effect .waves-ripple {
  background: #fff6;
}

body[data-topbar="dark"] .header-item, body[data-topbar="dark"] .header-item:hover {
  color: var(--bs-header-dark-item-color);
}

body[data-topbar="dark"] .header-profile-user {
  background-color: #ffffff40;
}

body[data-topbar="dark"] .noti-icon i {
  color: var(--bs-header-dark-item-color);
}

body[data-topbar="dark"] .logo-dark {
  display: none;
}

body[data-topbar="dark"] .logo-light {
  display: block;
}

body[data-topbar="dark"] .app-search .form-control {
  background-color: rgba(var(--bs-topbar-search-bg), .07);
  color: #fff;
}

body[data-topbar="dark"] .app-search span {
  color: #ffffff80;
}

body[data-topbar="dark"] .app-search input.form-control::-webkit-input-placeholder {
  color: #ffffff80;
}

body[data-sidebar="dark"] .navbar-brand-box {
  background: #2a3042;
}

body[data-sidebar="dark"] .logo-dark {
  display: none;
}

body[data-sidebar="dark"] .logo-light {
  display: block;
}

@media (width <= 600px) {
  .navbar-header .dropdown {
    position: static;
  }

  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (width <= 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] .navbar-brand-box {
  width: auto;
}

body[data-layout="horizontal"] .page-content {
  margin-top: 70px;
  padding: 79px 12px 60px;
}

@media (width <= 992px) {
  body[data-layout="horizontal"] .page-content {
    margin-top: 15px;
  }
}

.page-title-box {
  padding-bottom: 24px;
}

.page-title-box .breadcrumb {
  background-color: #0000;
  padding: 0;
}

.page-title-box h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px !important;
}

.footer {
  color: var(--bs-footer-color);
  background-color: var(--bs-footer-bg);
  height: 60px;
  padding: 20px 18px;
  position: absolute;
  bottom: 0;
  left: 250px;
  right: 0;
}

@media (width <= 991.98px) {
  .footer {
    left: 0;
  }
}

.vertical-collpsed .footer {
  left: 70px;
}

@media (width <= 991.98px) {
  .vertical-collpsed .footer {
    left: 0;
  }
}

body[data-layout="horizontal"] .footer {
  left: 0 !important;
}

.right-bar {
  background-color: var(--bs-secondary-bg);
  z-index: 9999;
  width: 280px;
  transition: all .2s ease-out;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -290px;
  box-shadow: 0 0 24px #0000000f, 0 1px #00000005;
  float: right !important;
}

.right-bar .right-bar-toggle {
  background-color: var(--bs-dark);
  color: var(--bs-gray-200);
  text-align: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: block;
}

.right-bar .right-bar-toggle:hover {
  background-color: var(--bs-dark);
}

.rightbar-overlay {
  z-index: 9998;
  background-color: #343a408c;
  transition: all .2s ease-out;
  display: none;
  position: absolute;
  inset: 0;
}

.right-bar-enabled .right-bar {
  right: 0;
}

.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (width <= 767.98px) {
  .right-bar {
    overflow: auto;
  }

  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}

.metismenu {
  margin: 0;
}

.metismenu li {
  width: 100%;
  display: block;
}

.metismenu .mm-collapse, .metismenu .mm-collapse:not(.mm-show) {
  display: none;
}

.metismenu .mm-collapse.mm-show {
  display: block;
}

.metismenu .mm-collapsing {
  height: 0;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
  position: relative;
  overflow: hidden;
}

.vertical-menu {
  z-index: 1001;
  background: #fff;
  width: 250px;
  margin-top: 0;
  position: fixed;
  top: 70px;
  bottom: 0;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.main-content {
  margin-left: 250px;
  overflow: hidden;
}

.main-content .content {
  margin-top: 70px;
  padding: 0 15px 10px;
}

#sidebar-menu {
  padding: 10px 0 30px;
}

#sidebar-menu .mm-active > .has-arrow:after {
  transform: rotate(-180deg);
}

#sidebar-menu .has-arrow:after {
  content: "󰅀";
  float: right;
  font-family: Material Design Icons;
  font-size: 1rem;
  transition: transform .2s, -webkit-transform .2s;
  display: block;
}

#sidebar-menu ul li a {
  color: #545a6d;
  padding: .625rem 1.5rem;
  font-size: 13px;
  transition: all .4s;
  display: block;
  position: relative;
}

#sidebar-menu ul li a i {
  vertical-align: middle;
  color: #7f8387;
  min-width: 1.75rem;
  padding-bottom: .125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  transition: all .4s;
  display: inline-block;
}

#sidebar-menu ul li a:hover, #sidebar-menu ul li a:hover i {
  color: #383c40;
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  color: #545a6d;
  padding: .4rem 1.5rem .4rem 3.5rem;
  font-size: 13px;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: .4rem 1.5rem .4rem 4.5rem;
  font-size: 13px;
}

.menu-title {
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  text-transform: uppercase;
  color: #7f8387;
  font-size: 11px;
  font-weight: 600;
  padding: 12px 20px !important;
}

.mm-active, .mm-active > a, .mm-active > a i, .mm-active .active, .mm-active .active i, .mm-active > i {
  color: #556ee6 !important;
}

@media (width <= 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable .vertical-menu {
    display: block;
  }
}

.vertical-collpsed .main-content {
  margin-left: 70px;
}

.vertical-collpsed .navbar-brand-box {
  width: 70px !important;
}

.vertical-collpsed .logo span.logo-lg {
  display: none;
}

.vertical-collpsed .logo span.logo-sm {
  display: block;
}

.vertical-collpsed .vertical-menu {
  z-index: 5;
  position: absolute;
  width: 70px !important;
}

.vertical-collpsed .vertical-menu .simplebar-content-wrapper, .vertical-collpsed .vertical-menu .simplebar-mask {
  overflow: visible !important;
}

.vertical-collpsed .vertical-menu .simplebar-scrollbar {
  display: none !important;
}

.vertical-collpsed .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .badge, .vertical-collpsed .vertical-menu #sidebar-menu .collapse.in, .vertical-collpsed .vertical-menu #sidebar-menu .menu-title {
  display: none !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu .has-arrow:after {
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li {
  white-space: nowrap;
  position: relative;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a {
  min-height: 55px;
  padding: 15px 20px;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:active, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:focus, .vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a:hover {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  margin-left: 4px;
  font-size: 1.45rem;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a span {
  padding-left: 25px;
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  color: #556ee6;
  background-color: #f5f5f5;
  width: 260px;
  transition: none;
  position: relative;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #556ee6;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
  width: 190px;
  display: block;
  position: absolute;
  left: 70px;
  box-shadow: 3px 5px 10px #363d471a;
  height: auto !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul ul {
  box-shadow: 3px 5px 10px #363d471a;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 6;
  color: #545a6d;
  width: 190px;
  padding: 8px 20px;
  position: relative;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #383c40;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  z-index: 9999;
  background-color: #fff;
  padding: 5px 0;
  display: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  width: 190px;
  margin-top: -36px;
  display: block;
  position: absolute;
  left: 190px;
  height: auto !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  top: 12px;
  right: 20px;
  transform: rotate(270deg);
}

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul li.active a {
  color: #f8f9fa;
}

body[data-sidebar="dark"] .vertical-menu {
  background: #2a3042;
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #a6b0cf;
}

body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #6a7187;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover, body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #fff;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #79829c;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 1760px;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  color: #fff;
  background: #2e3548;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #fff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  color: #79829c;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #fff;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  background-color: #2a3042;
}

body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active, body[data-sidebar="dark"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i, body[data-sidebar="dark"] .mm-active, body[data-sidebar="dark"] .mm-active > a, body[data-sidebar="dark"] .mm-active > a i, body[data-sidebar="dark"] .mm-active > i, body[data-sidebar="dark"] .mm-active .active, body[data-sidebar="dark"] .mm-active .active i {
  color: #fff !important;
}

body[data-sidebar="dark"] .menu-title {
  color: #6a7187;
}

body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size="small"] .navbar-brand-box {
  width: 160px;
}

body[data-sidebar-size="small"] .vertical-menu {
  text-align: center;
  width: 160px;
}

body[data-sidebar-size="small"] .vertical-menu .badge, body[data-sidebar-size="small"] .vertical-menu .has-arrow:after {
  display: none !important;
}

body[data-sidebar-size="small"] .main-content {
  margin-left: 160px;
}

body[data-sidebar-size="small"] .footer {
  left: 160px;
}

@media (width <= 991.98px) {
  body[data-sidebar-size="small"] .footer {
    left: 0;
  }
}

body[data-sidebar-size="small"] #sidebar-menu ul li.menu-title {
  background-color: #2e3548;
}

body[data-sidebar-size="small"] #sidebar-menu ul li a i {
  display: block;
}

body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li a, body[data-sidebar-size="small"] #sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding-left: 1.5rem;
}

body[data-sidebar-size="small"].vertical-collpsed .main-content {
  margin-left: 70px;
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu {
  text-align: left;
}

body[data-sidebar-size="small"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li > a i {
  display: inline-block;
}

body[data-sidebar-size="small"].vertical-collpsed .footer {
  left: 70px;
}

body[data-sidebar="colored"] .vertical-menu, body[data-sidebar="colored"] .navbar-brand-box {
  background-color: #556ee6;
}

body[data-sidebar="colored"] .navbar-brand-box .logo-dark {
  display: none;
}

body[data-sidebar="colored"] .navbar-brand-box .logo-light {
  display: block;
}

body[data-sidebar="colored"] .mm-active, body[data-sidebar="colored"] .mm-active > a, body[data-sidebar="colored"] .mm-active > a i, body[data-sidebar="colored"] .mm-active .active, body[data-sidebar="colored"] .mm-active > i {
  color: #fff !important;
}

body[data-sidebar="colored"] #sidebar-menu ul li.menu-title, body[data-sidebar="colored"] #sidebar-menu ul li a, body[data-sidebar="colored"] #sidebar-menu ul li a i {
  color: #fff9;
}

body[data-sidebar="colored"] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: #ffffff1a;
}

body[data-sidebar="colored"] #sidebar-menu ul li a:hover, body[data-sidebar="colored"] #sidebar-menu ul li a:hover i {
  color: #fff;
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a {
  color: #ffffff80;
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  color: #fff;
  background-color: #5e76e7;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #fff;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active {
  color: #556ee6 !important;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #556ee6;
}

body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active, body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active > a, body[data-sidebar="colored"].vertical-collpsed .vertical-menu #sidebar-menu ul li ul.sub-menu li.mm-active > a i {
  color: #556ee6 !important;
}

.topnav {
  background: var(--bs-topnav-bg);
  z-index: 100;
  margin-top: 70px;
  padding: 0 12px;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  color: var(--bs-menu-item-color);
  padding: 1rem 1.3rem;
  font-size: 14px;
  position: relative;
}

.topnav .navbar-nav .nav-link i {
  font-size: 15px;
}

.topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .nav-link:hover {
  color: var(--bs-menu-item-active-color);
  background-color: #0000;
}

.topnav .navbar-nav .dropdown-item {
  color: var(--bs-menu-item-color);
}

.topnav .navbar-nav .dropdown-item.active, .topnav .navbar-nav .dropdown-item:hover, .topnav .navbar-nav .nav-item .nav-link.active {
  color: var(--bs-menu-item-active-color);
}

.topnav .navbar-nav .dropdown.active > a {
  color: var(--bs-menu-item-active-color);
  background-color: #0000;
}

@media (width >= 1200px) {
  body[data-layout="horizontal"] .container-fluid, body[data-layout="horizontal"] .navbar-header {
    max-width: 85%;
  }
}

@media (width >= 992px) {
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }

  .topnav .dropdown-item {
    min-width: 180px;
    padding: .5rem 1.5rem;
  }

  .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
    left: 0;
    right: auto;
  }

  .topnav .dropdown .dropdown-menu {
    border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
    margin-top: 0;
  }

  .topnav .dropdown .dropdown-menu .arrow-down:after {
    position: absolute;
    right: 15px;
    transform: rotate(-135deg)translateY(-50%);
  }

  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0 !important;
  }

  .topnav .dropdown:hover > .dropdown-menu, .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .navbar-toggle {
    display: none;
  }
}

.arrow-down {
  display: inline-block;
}

.arrow-down:after {
  border-color: initial;
  content: "";
  transform-origin: top;
  border-style: solid;
  border-width: 0 0 1px 1px;
  width: .4em;
  height: .4em;
  margin-left: 10px;
  transition: all .3s ease-out;
  display: inline-block;
  top: 50%;
  right: 5px;
  transform: rotate(-45deg)translateY(-50%);
}

@media (width <= 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    left: auto;
    right: 100%;
  }
}

@media (width <= 991.98px) {
  .navbar-brand-box .logo-dark, .navbar-brand-box .logo-dark span.logo-sm {
    display: var(--bs-display-block);
  }

  .navbar-brand-box .logo-light {
    display: var(--bs-display-none);
  }

  .topnav {
    max-height: 360px;
    padding: 0;
    overflow-y: auto;
  }

  .topnav .navbar-nav .nav-link {
    padding: .75rem 1.1rem;
  }

  .topnav .dropdown .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #0000;
    border: none;
    padding-left: 15px;
  }

  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
    width: auto;
  }

  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
    margin: 0;
  }

  .topnav .dropdown .dropdown-item {
    background-color: #0000;
    position: relative;
  }

  .topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
    color: #556ee6;
  }

  .topnav .arrow-down:after {
    position: absolute;
    right: 15px;
  }
}

@media (width >= 992px) {
  body[data-layout="horizontal"][data-topbar="light"] .navbar-brand-box .logo-dark {
    display: var(--bs-display-block);
  }

  body[data-layout="horizontal"][data-topbar="light"] .navbar-brand-box .logo-light {
    display: var(--bs-display-none);
  }

  body[data-layout="horizontal"][data-topbar="light"] .topnav {
    background-color: #556ee6;
  }

  body[data-layout="horizontal"][data-topbar="light"] .topnav .navbar-nav .nav-link {
    color: #fff9;
  }

  body[data-layout="horizontal"][data-topbar="light"] .topnav .navbar-nav .nav-link:focus, body[data-layout="horizontal"][data-topbar="light"] .topnav .navbar-nav .nav-link:hover {
    color: #ffffffe6;
  }

  body[data-layout="horizontal"][data-topbar="light"] .topnav .navbar-nav > .dropdown.active > a {
    color: #ffffffe6 !important;
  }
}

body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #556ee6;
}

body[data-layout="horizontal"][data-topbar="colored"] .logo-dark {
  display: none;
}

body[data-layout="horizontal"][data-topbar="colored"] .logo-light {
  display: block;
}

body[data-layout="horizontal"][data-topbar="colored"] .app-search .form-control {
  background-color: rgba(var(--bs-topbar-search-bg), .07);
  color: #fff;
}

body[data-layout="horizontal"][data-topbar="colored"] .app-search span {
  color: #ffffff80;
}

body[data-layout="horizontal"][data-topbar="colored"] .app-search input.form-control::-webkit-input-placeholder {
  color: #ffffff80;
}

body[data-layout="horizontal"][data-topbar="colored"] .header-item, body[data-layout="horizontal"][data-topbar="colored"] .header-item:hover {
  color: var(--bs-header-dark-item-color);
}

body[data-layout="horizontal"][data-topbar="colored"] .navbar-header .dropdown .show.header-item {
  background-color: #ffffff1a;
}

body[data-layout="horizontal"][data-topbar="colored"] .navbar-header .waves-effect .waves-ripple {
  background: #fff6;
}

body[data-layout="horizontal"][data-topbar="colored"] .noti-icon i {
  color: var(--bs-header-dark-item-color);
}

@media (width >= 992px) {
  body[data-layout="horizontal"][data-topbar="colored"] .topnav {
    background-color: #556ee6;
  }

  body[data-layout="horizontal"][data-topbar="colored"] .topnav .navbar-nav .nav-link {
    color: #fff9;
  }

  body[data-layout="horizontal"][data-topbar="colored"] .topnav .navbar-nav .nav-link:focus, body[data-layout="horizontal"][data-topbar="colored"] .topnav .navbar-nav .nav-link:hover {
    color: #ffffffe6;
  }

  body[data-layout="horizontal"][data-topbar="colored"] .topnav .navbar-nav > .dropdown.active > a {
    color: #ffffffe6 !important;
  }
}

body[data-layout-size="boxed"] {
  background-color: var(--bs-boxed-body-bg);
}

body[data-layout-size="boxed"] #layout-wrapper {
  background-color: var(--bs-body-bg);
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

body[data-layout-size="boxed"] #page-topbar {
  max-width: 1300px;
  margin: 0 auto;
}

body[data-layout-size="boxed"] .footer {
  max-width: 1050px;
  margin: 0 auto;
}

body[data-layout-size="boxed"].vertical-collpsed .footer {
  max-width: 1230px;
}

body[data-layout="horizontal"][data-layout-size="boxed"] #layout-wrapper, body[data-layout="horizontal"][data-layout-size="boxed"] #page-topbar, body[data-layout="horizontal"][data-layout-size="boxed"] .footer {
  max-width: 100%;
}

body[data-layout="horizontal"][data-layout-size="boxed"] .container-fluid, body[data-layout="horizontal"][data-layout-size="boxed"] .navbar-header {
  max-width: 1300px;
}

@media (width >= 992px) {
  body[data-layout-scrollable="true"] #page-topbar, body[data-layout-scrollable="true"] .vertical-menu, body[data-layout-scrollable="true"][data-layout="horizontal"] #page-topbar, body[data-layout-scrollable="true"][data-layout="horizontal"] .topnav {
    position: absolute;
  }
}

.waves-effect {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.waves-effect .waves-ripple {
  opacity: 0;
  pointer-events: none;
  background: radial-gradient(#0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #fff0 70%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  transition: transform .5s ease-out, opacity .5s ease-out, -webkit-transform .5s ease-out;
  position: absolute;
  transform: scale(0)translate(0);
}

.waves-effect.waves-light .waves-ripple {
  background: radial-gradient(#fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.waves-effect.waves-classic .waves-ripple {
  background: #0003;
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: #fff6;
}

.waves-notransition {
  transition: none !important;
}

.waves-button, .waves-circle {
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

.waves-button, .waves-button-input, .waves-button:hover, .waves-button:visited {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: inherit;
  text-align: center;
  z-index: 1;
  background-color: #0000;
  border: none;
  outline: 0;
  font-size: 1em;
  line-height: 1em;
  text-decoration: none;
}

.waves-button {
  border-radius: .2em;
  padding: .85em 1.1em;
}

.waves-button-input {
  margin: 0;
  padding: .85em 1.1em;
}

.waves-input-wrapper {
  vertical-align: bottom;
  border-radius: .2em;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
}

.waves-circle {
  text-align: center;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
}

.waves-float {
  transition: all .3s;
  box-shadow: 0 1px 1.5px 1px #0000001f;
  -webkit-mask-image: none;
}

.waves-float:active {
  box-shadow: 0 8px 20px 1px #0000004d;
}

.waves-block {
  display: block;
}

.waves-effect.waves-light .waves-ripple {
  background-color: #fff6;
}

.waves-effect.waves-primary .waves-ripple {
  background-color: #556ee666;
}

.waves-effect.waves-success .waves-ripple {
  background-color: #34c38f66;
}

.waves-effect.waves-info .waves-ripple {
  background-color: #50a5f166;
}

.waves-effect.waves-warning .waves-ripple {
  background-color: #f1b44c66;
}

.waves-effect.waves-danger .waves-ripple {
  background-color: #f46a6a66;
}

.avatar-xs {
  width: 2rem;
  height: 2rem;
}

.avatar-sm {
  width: 3rem;
  height: 3rem;
}

.avatar-md {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar-lg {
  width: 6rem;
  height: 6rem;
}

.avatar-xl {
  width: 7.5rem;
  height: 7.5rem;
}

.avatar-title {
  color: #fff;
  background-color: #556ee6;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 500;
  display: flex;
}

.avatar-group {
  flex-wrap: wrap;
  padding-left: 12px;
  display: flex;
}

.avatar-group .avatar-group-item {
  border: 2px solid var(--bs-secondary-bg);
  border-radius: 50%;
  margin-left: -12px;
  transition: all .2s;
}

.avatar-group .avatar-group-item:hover {
  position: relative;
  transform: translateY(-2px);
}

.custom-accordion .accordion-list {
  background-color: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  font-weight: 600;
  display: flex;
}

.custom-accordion .accordion-list.collapsed i.accor-plus-icon:before {
  content: "󰐕";
}

.custom-accordion .accordion-list .accor-plus-icon {
  background-color: var(--bs-secondary-bg);
  text-align: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
}

.custom-accordion a.collapsed i.accor-down-icon:before {
  content: "󰅀";
}

.custom-accordion .card-body {
  color: var(--prefixsecondary-color);
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.social-list-item {
  color: #adb5bd;
  text-align: center;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: calc(2rem - 4px);
  transition: all .4s;
  display: block;
}

.social-list-item:hover {
  color: var(--bs-secondary-color);
  background-color: #eff2f7;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.alert-dismissible .btn-close {
  padding: 1.05rem 1.25rem;
  font-size: 10px;
}

.chartjs-chart {
  max-height: 300px;
}

#preloader {
  background-color: var(--bs-secondary-bg);
  z-index: 9999;
  position: fixed;
  inset: 0;
}

#status {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner-chase {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  animation: 2.5s linear infinite both spinner-chase;
  position: relative;
}

.chase-dot {
  width: 100%;
  height: 100%;
  animation: 2s ease-in-out infinite both chase-dot;
  position: absolute;
  top: 0;
  left: 0;
}

.chase-dot:before {
  content: "";
  background-color: #556ee6;
  border-radius: 100%;
  width: 25%;
  height: 25%;
  animation: 2s ease-in-out infinite both chase-dot-before;
  display: block;
}

.chase-dot:first-child, .chase-dot:first-child:before {
  animation-delay: -1.1s;
}

.chase-dot:nth-child(2), .chase-dot:nth-child(2):before {
  animation-delay: -1s;
}

.chase-dot:nth-child(3), .chase-dot:nth-child(3):before {
  animation-delay: -.9s;
}

.chase-dot:nth-child(4), .chase-dot:nth-child(4):before {
  animation-delay: -.8s;
}

.chase-dot:nth-child(5), .chase-dot:nth-child(5):before {
  animation-delay: -.7s;
}

.chase-dot:nth-child(6), .chase-dot:nth-child(6):before {
  animation-delay: -.6s;
}

@keyframes spinner-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes chase-dot {
  100%, 80% {
    transform: rotate(360deg);
  }
}

@keyframes chase-dot-before {
  50% {
    transform: scale(.4);
  }

  0%, 100% {
    transform: scale(1);
  }
}

[type="email"]::-webkit-input-placeholder {
  text-align: left;
}

[type="number"]::-webkit-input-placeholder {
  text-align: left;
}

[type="tel"]::-webkit-input-placeholder {
  text-align: left;
}

[type="url"]::-webkit-input-placeholder {
  text-align: left;
}

:is([type="email"]:-ms-input-placeholder, [type="number"]:-ms-input-placeholder, [type="tel"]:-ms-input-placeholder, [type="url"]:-ms-input-placeholder) {
  text-align: left;
}

[type="email"]::-moz-placeholder {
  text-align: left;
}

[type="number"]::-moz-placeholder {
  text-align: left;
}

[type="tel"]::-moz-placeholder {
  text-align: left;
}

[type="url"]::-moz-placeholder {
  text-align: left;
}

[type="email"]::placeholder, [type="number"]::placeholder, [type="tel"]::placeholder, [type="url"]::placeholder {
  text-align: left;
}

.form-check {
  text-align: left;
  position: relative;
}

.form-check-right {
  padding-left: 0;
  padding-right: 1.5em;
  display: inline-block;
}

.form-check-right .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-right .form-check-label {
  display: block;
}

.form-checkbox-outline .form-check-input {
  background-color: var(--bs-secondary-bg);
  border-width: 2px;
}

.form-checkbox-outline .form-check-input:active {
  filter: none;
}

.form-checkbox-outline .form-check-input:checked {
  background-color: var(--bs-secondary-bg) !important;
}

.form-checkbox-outline .form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-checkbox-outline .form-check-input:checked:after {
  content: "󰄬";
  color: var(--bs-body-color);
  font-family: Material Design Icons;
  font-size: 16px;
  position: absolute;
  left: 1px;
  top: -4px !important;
}

.form-radio-outline .form-check-input {
  background-color: var(--bs-secondary-bg);
  position: relative;
}

.form-radio-outline .form-check-input:active {
  filter: none;
}

.form-radio-outline .form-check-input:checked {
  background-color: var(--bs-secondary-bg) !important;
}

.form-radio-outline .form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-radio-outline .form-check-input:checked:after {
  content: "";
  border-radius: 50%;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 3px;
  top: 3px !important;
}

.form-check-primary .form-check-input:checked, .form-radio-primary .form-check-input:checked {
  background-color: #556ee6;
  border-color: #556ee6;
}

.form-radio-primary .form-check-input:checked:after {
  background-color: #556ee6;
}

.form-check-secondary .form-check-input:checked, .form-radio-secondary .form-check-input:checked {
  background-color: #74788d;
  border-color: #74788d;
}

.form-radio-secondary .form-check-input:checked:after {
  background-color: #74788d;
}

.form-check-success .form-check-input:checked, .form-radio-success .form-check-input:checked {
  background-color: #34c38f;
  border-color: #34c38f;
}

.form-radio-success .form-check-input:checked:after {
  background-color: #34c38f;
}

.form-check-info .form-check-input:checked, .form-radio-info .form-check-input:checked {
  background-color: #50a5f1;
  border-color: #50a5f1;
}

.form-radio-info .form-check-input:checked:after {
  background-color: #50a5f1;
}

.form-check-warning .form-check-input:checked, .form-radio-warning .form-check-input:checked {
  background-color: #f1b44c;
  border-color: #f1b44c;
}

.form-radio-warning .form-check-input:checked:after {
  background-color: #f1b44c;
}

.form-check-danger .form-check-input:checked, .form-radio-danger .form-check-input:checked {
  background-color: #f46a6a;
  border-color: #f46a6a;
}

.form-radio-danger .form-check-input:checked:after {
  background-color: #f46a6a;
}

.form-check-pink .form-check-input:checked, .form-radio-pink .form-check-input:checked {
  background-color: #e83e8c;
  border-color: #e83e8c;
}

.form-radio-pink .form-check-input:checked:after {
  background-color: #e83e8c;
}

.form-check-light .form-check-input:checked, .form-radio-light .form-check-input:checked {
  background-color: #eff2f7;
  border-color: #eff2f7;
}

.form-radio-light .form-check-input:checked:after {
  background-color: #eff2f7;
}

.form-check-dark .form-check-input:checked, .form-radio-dark .form-check-input:checked {
  background-color: #343a40;
  border-color: #343a40;
}

.form-radio-dark .form-check-input:checked:after {
  background-color: #343a40;
}

.form-check, .form-check-input, .form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.form-switch-md {
  min-height: 24px;
  padding-left: 2.5rem;
  line-height: 24px;
}

.form-switch-md .form-check-input {
  width: 40px;
  height: 20px;
  position: relative;
  left: -.5rem;
}

.form-switch-md .form-check-label {
  vertical-align: middle;
}

.form-switch-lg {
  min-height: 28px;
  padding-left: 2.75rem;
  line-height: 28px;
}

.form-switch-lg .form-check-input {
  width: 48px;
  height: 24px;
  position: relative;
  left: -.75rem;
}

.input-group-text {
  margin-bottom: 0;
}

.mini-stats-wid .mini-stat-icon {
  position: relative;
  overflow: hidden;
}

.mini-stats-wid .mini-stat-icon:after, .mini-stats-wid .mini-stat-icon:before {
  content: "";
  background-color: #ffffff1a;
  width: 8px;
  height: 54px;
  transition: all .4s;
  position: absolute;
  top: -5px;
  left: 16px;
  transform: rotate(32deg);
}

.mini-stats-wid .mini-stat-icon:after {
  width: 12px;
  transition: all .2s;
  left: -12px;
}

.mini-stats-wid:hover .mini-stat-icon:after {
  left: 60px;
}

.mfp-popup-form {
  max-width: 1140px;
}

.mfp-close {
  color: var(--bs-body-color) !important;
}

.bs-example-modal {
  z-index: 1;
  display: block;
  position: relative;
  inset: auto;
}

[dir="rtl"] .modal-open {
  padding-left: 0 !important;
}

.icon-demo-content {
  text-align: center;
  color: #adb5bd;
}

.icon-demo-content i {
  color: var(--prefixsecondary-color);
  margin-bottom: 16px;
  font-size: 24px;
  transition: all .4s;
  display: block;
}

.icon-demo-content .col-lg-4 {
  margin-top: 24px;
}

.icon-demo-content .col-lg-4:hover i {
  color: #556ee6;
  transform: scale(1.5);
}

.grid-structure .grid-container {
  background-color: var(--bs-gray-100);
  margin-top: 10px;
  padding: 10px 20px;
  font-size: .8rem;
  font-weight: 500;
}

.card-radio {
  background-color: var(--bs-secondary-bg);
  border: 2px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 1rem;
  overflow: hidden;
}

.card-radio:hover {
  cursor: pointer;
}

.card-radio-label {
  display: block;
}

.card-radio-input {
  display: none;
}

.card-radio-input:checked + .card-radio {
  border-color: #556ee6 !important;
}

.navs-carousel .owl-nav {
  margin-top: 16px;
}

.navs-carousel .owl-nav button {
  width: 30px;
  height: 30px;
  color: #556ee6 !important;
  background-color: #556ee640 !important;
  border-radius: 50% !important;
  margin: 4px 8px !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

@media print {
  .footer, .navbar-header, .page-title-box, .right-bar, .vertical-menu {
    display: none !important;
  }

  .card-body, .main-content, .page-content, .right-bar, body {
    margin: 0;
    padding: 0;
  }

  .card {
    border: 0;
  }
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  left: 0 !important;
  right: 0 !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  visibility: visible;
  scrollbar-width: none;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  overflow: auto;
  box-sizing: border-box !important;
  padding: 0 !important;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:after, .simplebar-content:before {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-height-auto-observer-wrapper {
  float: left;
  z-index: -1;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  max-width: 1px;
  height: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  width: 1000%;
  min-width: 1px;
  height: 1000%;
  min-height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  width: 4px;
  min-height: 10px;
  position: absolute;
  right: 2px;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #a2adb7;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  width: auto;
  min-width: 10px;
  height: 7px;
  min-height: 0;
  top: 2px;
  left: 0;
  right: auto;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  opacity: 0;
  visibility: hidden;
  direction: rtl;
  width: 500px;
  height: 500px;
  position: fixed;
  overflow: scroll hidden;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.custom-scroll {
  height: 100%;
}

.lnb-calendars-item {
  margin-right: 7px;
  display: inline-block;
}

input[type="checkbox"].tui-full-calendar-checkbox-round + span {
  margin-left: 0;
  margin-right: 4px;
}

.tui-full-calendar-layout, .tui-full-calendar-timegrid-timezone {
  background-color: var(--bs-secondary-bg) !important;
}

.tui-full-calendar-dayname-container, .tui-full-calendar-left, .tui-full-calendar-splitter, .tui-full-calendar-time-date, .tui-full-calendar-timegrid-gridline, .tui-full-calendar-timegrid-timezone, .tui-full-calendar-weekday-grid-line {
  border-color: var(--bs-border-color) !important;
}

.tui-full-calendar-weekday-exceed-in-week {
  text-align: center;
  color: var(--bs-body-color);
  border-color: var(--bs-border-color);
  border-radius: 4px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  background-color: var(--bs-secondary-bg) !important;
}

.tui-full-calendar-timegrid-hour {
  color: var(--bs-body-color) !important;
}

.tui-full-calendar-weekday-schedule-title {
  color: var(--bs-emphasis-color) !important;
}

.tui-full-calendar-weekday-schedule-title .tui-full-calendar-time-schedule {
  font-weight: 600;
}

.tui-full-calendar-popup-container {
  background-color: var(--bs-secondary-bg) !important;
  border-color: var(--bs-border-color) !important;
}

.tui-full-calendar-dropdown:hover .tui-full-calendar-dropdown-button, .tui-full-calendar-popup-section-item:focus, .tui-full-calendar-popup-section-item:hover {
  border-color: var(--bs-border-color);
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-fill {
  border-top-color: var(--bs-border-color) !important;
}

.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-fill, .tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-borde {
  border-bottom-color: var(--bs-border-color) !important;
}

.tui-full-calendar-button {
  color: var(--bs-emphasis-color);
  border-color: var(--bs-border-color);
  background-color: var(--bs-secondary-bg) !important;
}

.tui-full-calendar-popup-section-item {
  border-color: var(--bs-border-color);
}

.tui-full-calendar-month-dayname, .tui-full-calendar-weekday-border {
  border-top-color: var(--bs-border-color) !important;
}

.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-border {
  border-bottom-color: var(--bs-border-color) !important;
}

.tui-full-calendar-dropdown-menu {
  border-color: var(--bs-border-color) !important;
}

.tui-full-calendar-dropdown-menu, .tui-full-calendar-dropdown-menu-item {
  background-color: var(--bs-secondary-bg) !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-border {
  border-top-color: var(--bs-border-color);
}

.tui-full-calendar-content {
  background-color: var(--bs-secondary-bg) !important;
  color: var(--bs-emphasis-color) !important;
}

.tui-full-calendar-confirm {
  color: #fff !important;
  background-color: #f46a6a !important;
}

.tui-full-calendar-confirm:hover {
  color: #fff !important;
  background-color: #c35555 !important;
}

.tui-full-calendar-month-dayname-item span {
  color: var(--bs-emphasis-color) !important;
}

.tui-full-calendar-weekday-grid-line.tui-full-calendar-near-month-day.tui-full-calendar-extra-date .tui-full-calendar-weekday-grid-header .tui-full-calendar-weekday-grid-date {
  color: var(--bs-secondary-color) !important;
}

.tui-full-calendar-weekday-grid-line.tui-full-calendar-near-month-day .tui-full-calendar-weekday-grid-header .tui-full-calendar-weekday-grid-date {
  color: var(--bs-emphasis-color) !important;
}

.fc td, .fc th {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.fc .fc-toolbar h2 {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 30px;
}

@media (width <= 767.98px) {
  .fc .fc-toolbar .fc-center, .fc .fc-toolbar .fc-left, .fc .fc-toolbar .fc-right {
    float: none;
    text-align: center;
    clear: both;
    margin: 10px 0;
    display: block;
  }

  .fc .fc-toolbar > * > * {
    float: none;
  }

  .fc .fc-toolbar .fc-today-button {
    display: none;
  }
}

.fc .fc-toolbar .btn {
  text-transform: capitalize;
}

.fc .fc-col-header-cell-cushion, .fc .fc-daygrid-day-number {
  color: var(--bs-body-color);
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-next-button, .fc-prev-button {
  position: relative;
  padding: 6px 8px !important;
}

.fc-toolbar-chunk .fc-button-group .fc-button {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fc-toolbar-chunk .fc-button-group .fc-button.active, .fc-toolbar-chunk .fc-button-group .fc-button:hover {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.fc-toolbar-chunk .fc-today-button {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: #fff !important;
}

@media (width <= 575.98px) {
  .fc-toolbar {
    flex-direction: column;
    gap: 16px;
  }
}

.fc th.fc-widget-header {
  color: #495057;
  text-transform: uppercase;
  background: #f6f6f6;
  padding: 10px 0;
  font-weight: 600;
  line-height: 20px;
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
  border-color: #f6f6f6;
}

.fc-unthemed td.fc-today {
  background: #f6f8fa;
}

.fc-button {
  background: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
  color: #495057;
  text-transform: capitalize;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: auto !important;
  padding: 6px 12px !important;
}

.fc-state-active, .fc-state-disabled, .fc-state-down {
  color: #fff;
  text-shadow: none;
  background-color: #556ee6;
}

.fc-event {
  cursor: move;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin: 5px 7px;
  padding: 5px;
  font-size: .8125rem;
}

.fc-event.bg-dark {
  background-color: var(--bs-secondary-color) !important;
}

.fc-event.bg-dark .fc-event-time, .fc-event.bg-dark .fc-event-title {
  color: var(--bs-secondary-bg) !important;
}

.fc-event, .fc-event-dot {
  background-color: #556ee6;
}

.fc-event .fc-content {
  color: #fff;
}

#external-events .external-event {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  padding: 8px 16px;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
  color: #eff2f7;
}

[dir="rtl"] .fc-header-toolbar {
  direction: ltr !important;
}

[dir="rtl"] .fc-toolbar > * > :not(:first-child) {
  margin-left: .75em;
}

.fc-theme-standard .fc-scrollgrid {
  border-color: var(--bs-border-color);
}

.fc .fc-daygrid-week-number {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

.fc .fc-daygrid-more-link {
  padding: 5px;
  font-size: 11px;
  font-weight: 600;
}

.fc .fc-daygrid-more-link:hover {
  background-color: rgba(var(--bs-primary-rgb), .1);
}

.fc .fc-popover-header {
  padding: 6px 12px;
}

.fc-theme-standard .fc-popover-header {
  background: var(--bs-body-bg);
}

.fc-theme-standard .fc-popover {
  background: var(--bs-secondary-bg);
  border: 1px solid var(--bs-border-color);
}

.task-box {
  border: 1px solid var(--bs-border-color);
}

.gu-transit {
  border: 1px dashed var(--bs-border-color) !important;
  background-color: var(--bs-gray-200) !important;
}

#session-timeout-dialog .close {
  display: none;
}

#session-timeout-dialog .countdown-holder {
  color: #f46a6a;
  font-weight: 500;
}

#session-timeout-dialog .btn-default {
  color: #f46a6a;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
}

.irs {
  font-family: var(--bs-font-sans-serif);
}

.irs--square {
  cursor: pointer;
}

.irs--square .irs-bar, .irs--square .irs-from, .irs--square .irs-single, .irs--square .irs-to {
  font-size: 11px;
  background: #556ee6 !important;
}

.irs--square .irs-from:before, .irs--square .irs-single:before, .irs--square .irs-to:before {
  border-top-color: #556ee6;
}

.irs--square .irs-line {
  background: var(--bs-tertiary-bg);
  border-color: var(--bs-tertiary-bg);
}

.irs--square .irs-grid-text {
  color: var(--bs-secondary-color);
  font-size: 11px;
}

.irs--square .irs-max, .irs--square .irs-min {
  color: var(--bs-secondary-color);
  background: var(--bs-tertiary-bg);
  font-size: 11px;
}

.irs--square .irs-handle {
  cursor: pointer;
  border: 2px solid #556ee6;
  width: 12px;
  height: 12px;
  top: 26px;
  background-color: var(--bs-secondary-bg) !important;
}

.irs--square .irs-handle:active {
  cursor: pointer;
}

.irs--square .irs-grid-pol {
  background-color: var(--bs-secondary-color);
}

.swal2-container .swal2-title {
  font-size: 20px;
  font-weight: 500;
}

.swal2-modal {
  font-size: 14px;
}

.swal2-icon.swal2-question {
  color: #50a5f1;
  border-color: #50a5f1;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #34c38f;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #34c38f4d;
}

.swal2-icon.swal2-warning {
  color: #f1b44c;
  border-color: #f1b44c;
}

.swal2-styled:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.swal2-progress-steps .swal2-progress-step, .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #556ee6;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #556ee64d;
}

.swal2-progress-steps .swal2-progress-step-line {
  background: #556ee6;
}

.swal2-loader {
  border-color: #556ee6 #0000;
}

.swal2-popup {
  background: var(--bs-secondary-bg);
}

.swal2-html-container, .swal2-title {
  color: var(--bs-emphasis-color);
}

.swal2-file, .swal2-input, .swal2-textarea {
  border-color: var(--bs-border-color);
  color: var(--bs-body-color);
}

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--bs-border-color);
}

div:where(.swal2-container) div:where(.swal2-popup) {
  color: var(--bs-secondary-color);
}

.symbol {
  border-color: var(--bs-secondary-bg);
}

.rating-symbol-background, .rating-symbol-foreground {
  font-size: 24px;
}

.symbol-empty {
  background-color: var(--bs-gray-400);
}

.rating-symbol-foreground {
  top: 0;
}

.rating-star > span {
  vertical-align: middle;
  display: inline-block;
}

.rating-star > span.badge {
  margin-left: 4px;
}

#toast-container > div {
  opacity: 1;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

#toast-container > div:hover {
  opacity: .9;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

#toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
  min-width: 96%;
  margin: 4px auto;
}

.toast-primary {
  background-color: #556ee6cc !important;
  border: 2px solid #556ee6 !important;
}

.toast-secondary {
  background-color: #74788dcc !important;
  border: 2px solid #74788d !important;
}

.toast-success {
  background-color: #34c38fcc !important;
  border: 2px solid #34c38f !important;
}

.toast-info {
  background-color: #50a5f1cc !important;
  border: 2px solid #50a5f1 !important;
}

.toast-warning {
  background-color: #f1b44ccc !important;
  border: 2px solid #f1b44c !important;
}

.toast-danger {
  background-color: #f46a6acc !important;
  border: 2px solid #f46a6a !important;
}

.toast-pink {
  background-color: #e83e8ccc !important;
  border: 2px solid #e83e8c !important;
}

.toast-light {
  background-color: #eff2f7cc !important;
  border: 2px solid #eff2f7 !important;
}

.toast-dark {
  background-color: #343a40cc !important;
  border: 2px solid #343a40 !important;
}

.toast-error {
  background-color: #f46a6acc;
  border: 2px solid #f46a6a;
}

.toastr-options {
  background-color: var(--bs-tertiary-bg);
  border: 1px solid var(--bs-border-color);
  margin-bottom: 0;
  padding: 24px;
}

.error {
  color: #f46a6a;
}

.parsley-error {
  border-color: #f46a6a;
}

.parsley-errors-list {
  margin: 0;
  padding: 0;
  display: none;
}

.parsley-errors-list.filled {
  display: block;
}

.parsley-errors-list > li {
  color: #f46a6a;
  margin-top: 5px;
  font-size: 12px;
  list-style: none;
}

.select2-container .select2-selection--single {
  background-color: var(--bs-secondary-bg);
  border: 1px solid var(--bs-border-color-translucent);
  height: 38px;
}

.select2-container .select2-selection--single:focus {
  outline: 0;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: var(--bs-secondary-color);
  padding-left: .75rem;
  line-height: 36px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  width: 34px;
  height: 34px;
  right: 3px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: var(--bs-gray-500) transparent transparent transparent;
  border-width: 6px 6px 0;
}

.select2-container .select2-selection--single .select2-selection__placeholder {
  color: var(--bs-body-color);
}

[dir="rtl"] .select2-selection__rendered {
  text-align: end;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent var(--bs-gray-500) transparent !important;
  border-width: 0 6px 6px !important;
}

.select2-container--default .select2-search--dropdown {
  background-color: var(--bs-secondary-bg);
  padding: 10px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid var(--bs-border-color-translucent);
  background-color: var(--bs-secondary-bg);
  color: var(--bs-secondary-color);
  outline: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #556ee6;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: var(--bs-light);
  color: var(--bs-emphasis-color);
}

.select2-container--default .select2-results__option[aria-selected="true"]:hover {
  color: #fff;
  background-color: #556ee6;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: var(--bs-emphasis-color);
  border-right: none;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: .75rem;
}

.select2-dropdown {
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-secondary-bg);
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.select2-search input {
  border: 1px solid var(--bs-border-color);
}

.select2-container .select2-selection--multiple {
  background-color: var(--bs-secondary-bg);
  min-height: 38px;
  padding: 2px .75rem;
  border: 1px solid var(--bs-border-color-translucent) !important;
}

.select2-container .select2-selection--multiple .select2-search__field {
  color: var(--bs-emphasis-color);
  border: 0;
  margin: 7px 0 0;
}

.select2-container .select2-selection--multiple .select2-search__field::-webkit-input-placeholder {
  color: var(--bs-secondary-color);
}

.select2-container .select2-selection--multiple .select2-search__field::placeholder {
  color: var(--bs-secondary-color);
}

.select2-container .select2-selection--multiple .select2-search__field:-ms-input-placeholder {
  color: var(--bs-secondary-color);
}

.select2-container .select2-selection--multiple .select2-search__field::placeholder {
  color: var(--bs-secondary-color);
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--bs-tertiary-bg);
  border: 1px solid var(--bs-border-color);
  border-radius: 1px;
  padding: 0 7px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: var(--bs-border-color-translucent);
}

.select2-container--default .select2-results__group {
  font-weight: 600;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 16px;
}

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 10px;
}

.select2-result-repository__avatar img {
  border-radius: 2px;
  width: 100%;
  height: auto;
}

.select2-result-repository__statistics {
  margin-top: 7px;
}

.select2-result-repository__forks, .select2-result-repository__stargazers, .select2-result-repository__watchers {
  color: var(--bs-secondary-color);
  margin-right: 1em;
  font-size: 11px;
  display: inline-block;
}

.select2-result-repository__forks .fa, .select2-result-repository__stargazers .fa, .select2-result-repository__watchers .fa {
  margin-right: 4px;
}

.select2-result-repository__forks .fa.fa-flash:before, .select2-result-repository__stargazers .fa.fa-flash:before, .select2-result-repository__watchers .fa.fa-flash:before {
  content: "";
  font-family: "Font Awesome 5 Free";
}

.select2-results__option--highlighted .select2-result-repository__forks, .select2-results__option--highlighted .select2-result-repository__stargazers, .select2-results__option--highlighted .select2-result-repository__watchers {
  color: #fffc;
}

.select2-result-repository__meta {
  overflow: hidden;
}

.img-flag {
  width: 18px;
  height: 15px;
  margin-right: 7px;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--bs-tertiary-bg);
}

input[switch] {
  display: none;
}

input[switch] + label {
  cursor: pointer;
  text-align: center;
  background-color: #ced4da;
  background-image: none;
  border-radius: 2rem;
  width: 56px;
  height: 24px;
  padding: .16667rem;
  font-size: 1em;
  font-weight: 500;
  line-height: 1;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
}

input[switch] + label:before {
  color: #343a40;
  content: attr(data-off-label);
  text-align: center;
  min-width: 1.66667rem;
  margin: 3px;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: -2px;
  right: 1px;
  overflow: hidden;
}

input[switch] + label:after {
  content: "";
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eff2f7;
  border-radius: 2rem;
  width: 20px;
  height: 20px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 2px;
  left: 3px;
}

input[switch]:checked + label {
  background-color: #556ee6;
}

input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  left: 3px;
  right: auto;
}

input[switch]:checked + label:after {
  background-color: #eff2f7;
  left: 33px;
}

input[switch="bool"] + label {
  background-color: #f46a6a;
}

input[switch="bool"] + label:before, input[switch="bool"]:checked + label:before, input[switch="default"]:checked + label:before {
  color: #fff;
}

input[switch="bool"]:checked + label {
  background-color: #34c38f;
}

input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}

input[switch="primary"]:checked + label {
  background-color: #556ee6;
}

input[switch="success"]:checked + label {
  background-color: #34c38f;
}

input[switch="info"]:checked + label {
  background-color: #50a5f1;
}

input[switch="warning"]:checked + label {
  background-color: #f1b44c;
}

input[switch="danger"]:checked + label {
  background-color: #f46a6a;
}

input[switch="dark"]:checked + label {
  background-color: #343a40;
}

input[switch="dark"]:checked + label:before {
  color: #eff2f7;
}

.square-switch {
  margin-right: 7px;
}

.square-switch input[switch] + label, .square-switch input[switch] + label:after {
  border-radius: 4px;
}

.sp-container {
  background-color: var(--bs-secondary-bg);
}

.sp-container button {
  color: #343a40;
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .71094rem;
  font-weight: 400;
}

.sp-container button.sp-palette-toggle {
  background-color: #eff2f7;
}

.sp-container button.sp-choose {
  background-color: #34c38f;
  margin-left: 5px;
  margin-right: 0;
}

.sp-palette-container {
  border-right: 1px solid #eff2f7;
}

.sp-input {
  background-color: var(--bs-secondary-bg);
  color: var(--bs-emphasis-color);
  border-color: var(--bs-border-color-translucent) !important;
}

.sp-input:focus {
  outline: 0;
}

[dir="rtl"] .sp-alpha {
  direction: ltr;
}

[dir="rtl"] .sp-original-input-container .sp-add-on {
  border-radius: 4px 0 0 4px !important;
}

[dir="rtl"] input.spectrum.with-add-on {
  border: 1px solid var(--bs-border-color-translucent);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
  border-left: 0;
}

.bootstrap-timepicker-widget table td a {
  color: var(--bs-emphasis-color);
}

.bootstrap-timepicker-widget table td a:hover {
  color: #556ee6;
  background-color: #0000;
  border-color: #0000;
  border-radius: 4px;
  text-decoration: none;
}

.bootstrap-timepicker-widget table td input {
  color: var(--bs-body-color);
  border: 0;
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-secondary-bg);
  width: 32px;
  height: 32px;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom-color: var(--bs-gray-200);
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-top-color: var(--bs-gray-200);
}

.timepicker-orient-top {
  top: calc(1.5em + .94rem + calc(var(--bs-border-width) * 2)) !important;
}

.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + .94rem + calc(var(--bs-border-width) * 2)) !important;
}

.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
  right: auto;
}

.datepicker {
  border: 1px solid var(--bs-border-color);
  padding: 8px;
  z-index: 999 !important;
}

.datepicker table tr th {
  font-weight: 500;
}

.datepicker table tr td .active.disabled, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td.active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover, .datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover, .datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-image: none;
  color: #fff !important;
  background-color: #556ee6 !important;
}

.datepicker table tr td span.focused, .datepicker table tr td span:hover, .datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  background: var(--bs-light);
}

.datepicker table tr td span.new, .datepicker table tr td span.old, .datepicker table tr td.new, .datepicker table tr td.old {
  color: var(--bs-gray-500);
  opacity: .6;
}

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background-color: var(--bs-tertiary-bg);
}

.table-condensed > tbody > tr > td, .table-condensed > thead > tr > th {
  padding: 7px;
}

.bootstrap-datepicker-inline .datepicker-inline {
  display: inline-block;
  width: auto !important;
}

.datepicker-container {
  border: 1px solid var(--bs-border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--bs-secondary-bg);
}

.datepicker-container.datepicker-inline {
  width: 212px;
}

.datepicker {
  color: var(--bs-secondary-color);
}

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: var(--bs-light);
}

.datepicker-panel > ul > li {
  background-color: var(--bs-secondary-bg);
  border-radius: 4px;
}

.datepicker-panel > ul > li.picked, .datepicker-panel > ul > li.picked:hover {
  color: #556ee6;
  background-color: #556ee640;
}

.datepicker-panel > ul > li.highlighted, .datepicker-panel > ul > li.highlighted:hover, .datepicker-panel > ul > li:hover {
  color: #fff;
  background-color: #556ee6;
}

.datepicker-panel > ul > li.muted, .datepicker-panel > ul > li.muted:hover {
  color: var(--bs-gray-500);
  opacity: .6;
}

.datepicker-panel > ul[data-view="week"] > li {
  font-weight: 500;
}

.datepicker-panel > ul[data-view="week"] > li, .datepicker-panel > ul[data-view="week"] > li:hover {
  background-color: var(--bs-secondary-bg);
}

.bootstrap-touchspin.input-group > .input-group-prepend > .btn, .bootstrap-touchspin.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-touchspin.input-group > .input-group-append > .btn, .bootstrap-touchspin.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .tox-tinymce {
  border: 1px solid #ced4da;
}

form .tox :not(svg):not(rect) {
  color: #495057;
}

form .tox:not(.tox-tinymce-inline) .tox-editor-header {
  -webkit-box-shadow: var(--bs-box-shadow);
  box-shadow: var(--bs-box-shadow);
}

form .tox .tox-mbtn {
  color: #74788d;
}

form .tox .tox-mbtn:hover:not(:disabled):not(.tox-mbtn--active) {
  background-color: #f8f9fa;
}

form .tox .tox-tbtn:hover {
  color: #49505780;
  background-color: #f8f9fa;
}

form .tox .tox-tbtn--disabled svg, form .tox .tox-tbtn--disabled:hover svg, form .tox .tox-tbtn:disabled svg, form .tox .tox-tbtn:disabled:hover svg {
  fill: #49505780;
}

form .tox .tox-tbtn--bespoke {
  background-color: #f8f9fa;
}

.dropzone {
  border: 2px dashed var(--bs-border-color);
  background: var(--bs-secondary-bg);
  border-radius: 6px;
  min-height: 230px;
}

.dropzone .dz-message {
  width: 100%;
  margin: 3rem 0;
  font-size: 24px;
}

.form-wizard-wrapper label {
  text-align: right;
  font-size: 14px;
}

.wizard .steps > ul {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

@media (width <= 1199.98px) {
  .wizard .steps > ul {
    flex-direction: column;
  }
}

.wizard .steps > ul > a, .wizard .steps > ul > li {
  flex-grow: 1;
  flex-basis: 0;
}

.wizard .steps > ul > li {
  width: 100%;
}

.wizard .steps > ul > li a {
  color: var(--bs-secondary-color);
  background-color: #556ee61a;
  padding: .5rem 1rem;
  font-weight: 500;
  display: block;
}

.wizard .steps > ul .current-info {
  position: absolute;
  left: -999em;
}

.wizard .steps .number {
  color: #556ee6;
  text-align: center;
  border: 2px solid #556ee6;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-right: .5rem;
  line-height: 34px;
  display: inline-block;
}

.wizard .steps .current a, .wizard .steps .current a:active, .wizard .steps .current a:hover {
  color: var(--bs-emphasis-color);
  background-color: #556ee633;
}

.wizard .steps .current a .number, .wizard .steps .current a:active .number, .wizard .steps .current a:hover .number {
  color: #fff;
  background-color: #556ee6;
}

.wizard > .content {
  background-color: #0000;
  border-radius: 0;
  min-height: 150px;
  margin-top: 0;
  padding: 14px;
}

.wizard > .content > .title {
  position: absolute;
  left: -999em;
}

.wizard > .content > .body {
  width: 100%;
  height: 100%;
  padding: 14px 0 0;
  position: static;
}

.wizard > .actions {
  text-align: right;
  width: 100%;
  display: block;
  position: relative;
}

.wizard > .actions > ul {
  text-align: right;
  padding-left: 0;
  display: block;
}

.wizard > .actions > ul > li {
  margin: 0 .5em;
  display: inline-block;
}

.wizard > .actions a, .wizard > .actions a:active, .wizard > .actions a:hover {
  color: #fff;
  background-color: #556ee6;
  border-radius: 4px;
  padding: 8px 15px;
}

.wizard > .actions .disabled a, .wizard > .actions .disabled a:active, .wizard > .actions .disabled a:hover {
  opacity: .65;
  color: #fff;
  cursor: not-allowed;
  background-color: #556ee6;
}

.wizard.vertical-wizard {
  flex-wrap: wrap;
  display: flex;
}

.wizard.vertical-wizard .steps > ul {
  flex-direction: column;
}

.wizard.vertical-wizard .steps > ul > li {
  width: 100% !important;
}

.wizard.vertical-wizard .actions, .wizard.vertical-wizard .content, .wizard.vertical-wizard .steps {
  width: 100%;
}

@media (width >= 1200px) {
  .wizard.vertical-wizard .steps {
    width: 25%;
  }
}

.wizard.vertical-wizard .content {
  padding: 24px;
}

@media (width >= 1200px) {
  .wizard.vertical-wizard .content {
    width: 75%;
    padding: 12px 24px;
  }
}

.wizard.vertical-wizard .content > .body {
  padding: 0;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

@media (width <= 767px) {
  div.dataTables_wrapper div.dataTables_filter {
    text-align: center;
  }
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: .5em;
  margin-right: 0;
}

.table.dataTable, table.dataTable {
  border-collapse: collapse !important;
}

.table.dataTable.dtr-inline.collapsed > tbody > tr > td, table.dataTable.dtr-inline.collapsed > tbody > tr > td {
  position: relative;
}

.table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control {
  padding-left: 30px;
}

.table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before, table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before {
  color: #fff;
  box-sizing: content-box;
  text-align: center;
  content: "+";
  background-color: #556ee6;
  border: 2px solid #fff;
  border-radius: 14px;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: 50%;
  left: 5px;
  text-indent: 0 !important;
}

.table-rep-plugin .btn-toolbar {
  display: block;
}

.table-rep-plugin .table-responsive {
  border: none !important;
}

.table-rep-plugin .btn-group .btn-default {
  color: #eff2f7;
  background-color: #74788d;
  border: 1px solid #74788d;
}

.table-rep-plugin .btn-group .btn-default.btn-primary {
  color: #fff;
  background-color: #556ee6;
  border-color: #556ee6;
  box-shadow: 0 0 0 2px #556ee680;
}

.table-rep-plugin .btn-group.pull-right {
  float: right;
}

.table-rep-plugin .btn-group.pull-right .dropdown-menu {
  right: 0;
  top: 100% !important;
  transform: none !important;
}

.table-rep-plugin tbody th {
  font-size: 14px;
  font-weight: 400;
}

.table-rep-plugin .checkbox-row {
  padding-left: 40px;
  color: var(--bs-body-color) !important;
}

.table-rep-plugin .checkbox-row:hover {
  background-color: #f6f8fa !important;
}

.table-rep-plugin .checkbox-row label {
  padding-left: 5px;
  display: inline-block;
  position: relative;
}

.table-rep-plugin .checkbox-row label:before {
  -o-transition: .3s ease-in-out;
  content: "";
  background-color: #fff;
  border: 1px solid #f6f6f6;
  border-radius: 3px;
  width: 17px;
  height: 17px;
  margin-left: -20px;
  transition: all .3s ease-in-out;
  display: inline-block;
  position: absolute;
  left: 0;
  outline: 0 !important;
}

.table-rep-plugin .checkbox-row label:after {
  color: #eff2f7;
  width: 16px;
  height: 16px;
  margin-left: -20px;
  padding-top: 1px;
  padding-left: 3px;
  font-size: 11px;
  display: inline-block;
  position: absolute;
  top: -1px;
  left: 0;
}

.table-rep-plugin .checkbox-row input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: 0 !important;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label {
  opacity: .65;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:focus + label:before {
  outline-offset: -2px;
  outline: 0;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:disabled + label:before {
  cursor: not-allowed;
  background-color: #f8f9fa;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label:before {
  background-color: #556ee6;
  border-color: #556ee6;
}

.table-rep-plugin .checkbox-row input[type="checkbox"]:checked + label:after {
  color: #fff;
}

.table-rep-plugin .fixed-solution .sticky-table-header {
  background-color: #556ee6;
  top: 70px !important;
}

.table-rep-plugin .fixed-solution .sticky-table-header table {
  color: #fff;
}

.table-rep-plugin .sticky-table-header, .table-rep-plugin table.focus-on tbody tr.focused td, .table-rep-plugin table.focus-on tbody tr.focused th {
  color: #fff;
  background: #556ee6;
  border-color: #556ee6;
}

.table-rep-plugin .sticky-table-header table, .table-rep-plugin table.focus-on tbody tr.focused td table, .table-rep-plugin table.focus-on tbody tr.focused th table {
  color: #fff;
}

.table-rep-plugin table.focus-on tbody tr.unfocused td, .table-rep-plugin table.focus-on tbody tr.unfocused th, .table-rep-plugin table.focus-on tfoot tr.unfocused td, .table-rep-plugin table.focus-on tfoot tr.unfocused th {
  color: var(--bs-body-color);
}

@media (width >= 992px) {
  body[data-layout="horizontal"] .fixed-solution .sticky-table-header {
    top: 120px !important;
  }
}

.table-edits input, .table-edits select {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border: 1px solid var(--bs-border-color-translucent);
  background-color: var(--bs-secondary-bg);
  color: var(--bs-emphasis-color);
  border-radius: var(--bs-border-radius);
  padding: .25rem .5rem;
}

.table-edits input:focus, .table-edits select:focus {
  border-color: rgb(var(--bs-body-color-rgb), .2);
  outline: 0;
}

.apex-charts {
  min-height: 10px !important;
}

.apex-charts text {
  fill: var(--bs-gray-500);
  font-family: var(--bs-font-sans-serif) !important;
}

.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-text, .apexcharts-tooltip-title {
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-legend-series {
  font-weight: 500;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: var(--bs-border-color);
}

.apexcharts-legend-text {
  color: #74788d !important;
  font-family: var(--bs-font-sans-serif) !important;
  font-size: 13px !important;
}

.apexcharts-xaxis text, .apexcharts-yaxis text {
  fill: var(--bs-secondary-color);
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-gridline, .apexcharts-radialbar-track.apexcharts-track path {
  stroke: var(--bs-border-color);
}

.apexcharts-tooltip {
  background-color: var(--bs-secondary-bg) !important;
  border: 1px solid var(--bs-border-color) !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: var(--bs-border-color) !important;
  border-bottom: 1px solid var(--bs-border-color) !important;
}

.apexcharts-pie-area {
  stroke: var(--bs-secondary-bg);
}

.apexcharts-grid-borders line {
  stroke: var(--bs-border-color);
}

.apexcharts-pie-label {
  fill: var(--bs-white) !important;
}

.apexcharts-xaxis-tick {
  stroke: var(--bs-border-color);
}

.e-charts {
  height: 350px;
}

.flot-charts-height {
  height: 320px;
}

.flotTip {
  z-index: 100;
  color: #f8f9fa;
  background-color: #343a40e6;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.legendLabel {
  color: #adb5bd;
}

.jqstooltip {
  box-sizing: content-box;
  border-radius: 3px;
  box-shadow: 0 1rem 3rem #0000002d;
  background-color: #343a40 !important;
  border-color: #212529 !important;
  width: auto !important;
  height: auto !important;
  padding: 5px 10px !important;
}

.jqsfield {
  color: #eff2f7 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: var(--bs-font-sans-serif) !important;
  font-weight: 500 !important;
}

.gmaps, .gmaps-panaroma {
  background: #f8f9fa;
  border-radius: 3px;
  height: 300px !important;
}

.gmaps-overlay {
  text-align: center;
  color: #fff;
  background: #556ee6;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 40px;
  display: block;
}

.gmaps-overlay_arrow {
  width: 0;
  height: 0;
  margin-left: -16px;
  position: absolute;
  left: 50%;
}

.gmaps-overlay_arrow.above {
  border-top: 16px solid #556ee6;
  border-left: 16px solid #0000;
  border-right: 16px solid #0000;
  bottom: -15px;
}

.gmaps-overlay_arrow.below {
  border-bottom: 16px solid #556ee6;
  border-left: 16px solid #0000;
  border-right: 16px solid #0000;
  top: -15px;
}

.jvectormap-label {
  color: #f8f9fa;
  font-family: var(--bs-font-sans-serif);
  background: #343a40;
  border: none;
  padding: 5px 8px;
  font-size: .8125rem;
}

.leaflet-map {
  height: 300px;
}

.leaflet-map.leaflet-container {
  z-index: 99;
}

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.auth-logo .auth-logo-dark {
  display: var(--bs-display-block);
}

.auth-logo .auth-logo-light {
  display: var(--bs-display-none);
}

.auth-body-bg {
  background-color: var(--bs-secondary-bg);
}

.auth-pass-inputgroup input[type="input"] + .btn .mdi-eye-outline:before {
  content: "󰛑";
}

.auth-full-bg {
  background-color: #556ee640;
  display: flex;
}

@media (width >= 1200px) {
  .auth-full-bg {
    height: 100vh;
  }
}

.auth-full-bg:before {
  content: "";
  border-radius: 50%;
  width: 300px;
  height: 300px;
  position: absolute;
}

.auth-full-bg .bg-overlay {
  background: url("bg-auth-overlay.9e0c6580.png") center / cover no-repeat;
}

.auth-full-page-content {
  display: flex;
}

@media (width >= 1200px) {
  .auth-full-page-content {
    min-height: 100vh;
  }
}

.auth-review-carousel.owl-theme .owl-dots .owl-dot span {
  background-color: #556ee640;
}

.auth-review-carousel.owl-theme .owl-dots .owl-dot.active span, .auth-review-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #556ee6;
}

.search-box .form-control {
  border-radius: 30px;
  padding-left: 40px;
}

.search-box .search-icon {
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 13px;
}

.product-list li a {
  color: var(--bs-body-color);
  padding: 4px 0;
  display: block;
}

.product-view-nav.nav-pills .nav-item {
  margin-left: 4px;
}

.product-view-nav.nav-pills .nav-link {
  text-align: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 0;
  font-size: 16px;
  line-height: 36px;
}

.product-ribbon {
  position: absolute;
  top: 0;
  right: 0;
}

.product-detai-imgs .nav .nav-link {
  margin: 7px 0;
}

.product-detai-imgs .nav .nav-link.active {
  background-color: var(--bs-tertiary-bg);
}

.product-color a {
  text-align: center;
  color: var(--bs-body-color);
  display: inline-block;
}

.product-color a .product-color-item {
  margin: 7px;
}

.product-color a.active, .product-color a:hover {
  color: #556ee6;
}

.product-color a.active .product-color-item, .product-color a:hover .product-color-item {
  border-color: #556ee6 !important;
}

.visa-card .visa-logo {
  line-height: .5;
}

.visa-card .visa-pattern {
  color: #ffffff0d;
  font-size: 385px;
  line-height: .4;
  position: absolute;
  bottom: 0;
  right: 0;
}

.checkout-tabs .nav-pills .nav-link {
  text-align: center;
  background-color: var(--bs-secondary-bg);
  margin-bottom: 24px;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.checkout-tabs .nav-pills .nav-link.active {
  background-color: #556ee6;
}

.checkout-tabs .nav-pills .nav-link .check-nav-icon {
  font-size: 36px;
}

.email-leftbar {
  float: left;
  border-radius: 5px;
  width: 236px;
  padding: 20px;
}

.email-rightbar {
  margin-left: 260px;
}

.chat-user-box p.user-title {
  color: var(--bs-emphasis-color);
  font-weight: 500;
}

.chat-user-box p {
  font-size: 12px;
}

@media (width <= 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }

  .email-rightbar {
    margin: 0;
  }
}

.mail-list a {
  color: var(--bs-secondary-color);
  padding: 8px 5px;
  line-height: 24px;
  display: block;
}

.mail-list a.active {
  color: #f46a6a;
  font-weight: 500;
}

.message-list {
  padding-left: 0;
  display: block;
}

.message-list li {
  cursor: default;
  height: 50px;
  line-height: 50px;
  transition-duration: .3s;
  display: block;
  position: relative;
}

.message-list li a {
  color: var(--bs-secondary-color);
}

.message-list li:hover {
  background: var(--bs-tertiary-bg);
  transition-duration: 50ms;
}

.message-list li .col-mail {
  float: left;
  position: relative;
}

.message-list li .col-mail-1 {
  width: 320px;
}

.message-list li .col-mail-1 .checkbox-wrapper-mail, .message-list li .col-mail-1 .dot, .message-list li .col-mail-1 .star-toggle {
  float: left;
  display: block;
}

.message-list li .col-mail-1 .dot {
  border: 4px solid #0000;
  border-radius: 100px;
  width: 0;
  height: 0;
  margin: 22px 26px 0;
  font-size: 0;
  line-height: 0;
}

.message-list li .col-mail-1 .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
}

.message-list li .col-mail-1 .star-toggle {
  margin-top: 18px;
  margin-left: 5px;
}

.message-list li .col-mail-1 .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 110px;
  right: 0;
  overflow: hidden;
}

.message-list li .col-mail-2 {
  position: absolute;
  inset: 0 0 0 320px;
}

.message-list li .col-mail-2 .date, .message-list li .col-mail-2 .subject {
  position: absolute;
  top: 0;
}

.message-list li .col-mail-2 .subject {
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0;
  right: 200px;
  overflow: hidden;
}

.message-list li .col-mail-2 .date {
  width: 170px;
  padding-left: 80px;
  right: 0;
}

.message-list li.active, .message-list li.active:hover {
  box-shadow: inset 3px 0 #556ee6;
}

.message-list li.unread {
  background-color: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  font-weight: 500;
}

.message-list li.unread a {
  color: var(--bs-body-color);
  font-weight: 500;
}

.message-list .checkbox-wrapper-mail {
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  -webkit-box-shadow: inset 0 0 0 1px var(--bs-border-color-translucent);
  box-shadow: inset 0 0 0 1px var(--bs-border-color-translucent);
  border-radius: 1px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.message-list .checkbox-wrapper-mail input {
  opacity: 0;
  cursor: pointer;
}

.message-list .checkbox-wrapper-mail input:checked ~ label {
  opacity: 1;
}

.message-list .checkbox-wrapper-mail label {
  cursor: pointer;
  opacity: 0;
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  transition-duration: 50ms;
  position: absolute;
  top: 0;
  left: 0;
}

.message-list .checkbox-wrapper-mail label:before {
  content: "󰄬";
  color: var(--bs-body-color);
  width: 20px;
  height: 20px;
  margin-top: -16px;
  font-family: Material Design Icons;
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 4px;
}

@media (width <= 575.98px) {
  .message-list li .col-mail-1 {
    width: 200px;
  }
}

@media (width >= 1200px) {
  .filemanager-sidebar {
    min-width: 230px;
    max-width: 230px;
  }
}

@media (width >= 1366px) {
  .filemanager-sidebar {
    min-width: 280px;
    max-width: 280px;
  }
}

.categories-list {
  padding: 4px 0;
}

.categories-list li a {
  color: var(--bs-body-color);
  padding: 8px 12px;
  font-weight: 500;
  display: block;
}

.categories-list li.active a {
  color: #556ee6;
}

.categories-list li ul {
  padding-left: 16px;
}

.categories-list li ul li a {
  color: var(--bs-secondary-color);
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 400;
}

@media (width >= 992px) {
  .chat-leftsidebar {
    min-width: 260px;
  }
}

@media (width >= 1200px) {
  .chat-leftsidebar {
    min-width: 380px;
  }
}

.chat-leftsidebar .chat-leftsidebar-nav .nav {
  background-color: var(--bs-secondary-bg);
}

.chat-leftsidebar .chat-leftsidebar-nav .tab-content {
  min-height: 488px;
}

.chat-noti-dropdown.active:before {
  content: "";
  background-color: #f46a6a;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  right: 0;
}

.chat-noti-dropdown .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 6px;
  font-size: 20px;
}

.chat-search-box .form-control {
  border: 0;
}

.chat-list {
  margin: 0;
}

.chat-list li.active a {
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.chat-list li a {
  color: var(--bs-secondary-color);
  border-top: 1px solid var(--bs-border-color);
  border-radius: 4px;
  padding: 14px 16px;
  transition: all .4s;
  display: block;
}

.chat-list li a:hover {
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.user-chat-nav .dropdown .nav-btn {
  color: var(--bs-body-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--bs-light);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  line-height: 42px;
}

.user-chat-nav .dropdown .dropdown-menu {
  border: 1px solid var(--bs-border-color);
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.chat-conversation li {
  clear: both;
}

.chat-conversation .chat-day-title {
  text-align: center;
  margin-bottom: 24px;
  position: relative;
}

.chat-conversation .chat-day-title .title {
  background-color: var(--bs-secondary-bg);
  z-index: 1;
  padding: 6px 24px;
  position: relative;
}

.chat-conversation .chat-day-title:before {
  content: "";
  background-color: var(--bs-border-color);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}

.chat-conversation .conversation-list {
  margin-bottom: 24px;
  display: inline-block;
  position: relative;
}

.chat-conversation .conversation-list .ctext-wrap {
  background-color: #556ee61a;
  border-radius: 8px 8px 8px 0;
  padding: 12px 24px;
  overflow: hidden;
}

.chat-conversation .conversation-list .ctext-wrap .conversation-name {
  color: #556ee6;
  margin-bottom: 4px;
  font-weight: 600;
}

.chat-conversation .conversation-list .dropdown {
  float: right;
}

.chat-conversation .conversation-list .dropdown .dropdown-toggle {
  color: var(--bs-secondary-color);
  padding: 4px;
  font-size: 18px;
}

@media (width <= 575.98px) {
  .chat-conversation .conversation-list .dropdown .dropdown-toggle {
    display: none;
  }
}

.chat-conversation .conversation-list .dropdown .dropdown-menu {
  border: 1px solid var(--bs-border-color);
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.chat-conversation .conversation-list .chat-time {
  font-size: 12px;
}

.chat-conversation .right .conversation-list {
  float: right;
}

.chat-conversation .right .conversation-list .ctext-wrap {
  background-color: var(--bs-light);
  text-align: right;
  border-radius: 8px 8px 0;
}

.chat-conversation .right .conversation-list .dropdown {
  float: left;
}

.chat-conversation .right .conversation-list.last-chat .conversation-list:before {
  left: auto;
  right: 0;
}

.chat-conversation .last-chat .conversation-list:before {
  content: "󰀉";
  color: #556ee6;
  font-family: Material Design Icons;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (width <= 575.98px) {
  .chat-conversation .last-chat .conversation-list:before {
    display: none;
  }
}

.chat-input-section {
  border-top: 1px solid var(--bs-border-color);
}

.chat-input {
  border-radius: 30px;
  padding-right: 120px;
  background-color: var(--bs-light) !important;
  border-color: var(--bs-light) !important;
}

.chat-input-links {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.chat-input-links li a {
  padding: 0 4px;
  font-size: 16px;
  line-height: 36px;
  display: inline-block;
}

@media (width <= 575.98px) {
  .chat-send {
    min-width: auto;
  }
}

.project-list-table {
  border-collapse: separate;
  border-spacing: 0 12px;
}

.project-list-table tr {
  background-color: var(--bs-secondary-bg);
}

.contact-links a {
  color: var(--bs-body-color);
}

.profile-user-wid {
  margin-top: -26px;
}

@media (width >= 576px) {
  .currency-value {
    position: relative;
  }

  .currency-value:after {
    content: "󰓡";
    text-align: center;
    color: #fff;
    z-index: 9;
    background-color: #556ee6;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    font-family: Material Design Icons;
    font-size: 24px;
    line-height: 45px;
    position: absolute;
    top: 50%;
    right: -34px;
    transform: translateY(-50%);
  }
}

.crypto-buy-sell-nav-content {
  border: 2px solid var(--bs-border-color);
  border-top: 0;
}

.kyc-doc-verification .dropzone {
  min-height: 180px;
}

.kyc-doc-verification .dropzone .dz-message {
  margin: 24px 0;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

.section.bg-white {
  background-color: var(--bs-secondary-bg) !important;
}

.small-title {
  color: var(--bs-secondary-color);
  margin-bottom: 8px;
}

.navigation {
  z-index: 999;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  margin-bottom: 0;
  padding: 0 16px;
  transition: all .5s ease-in-out;
}

@media (width <= 991.98px) {
  .navigation {
    background-color: var(--bs-topnav-bg);
  }
}

.navigation .navbar-logo {
  line-height: 70px;
  transition: all .4s;
}

.navigation .navbar-logo .logo-dark {
  display: none;
}

@media (width <= 991.98px) {
  .navigation .navbar-logo .logo-dark {
    display: block;
  }
}

.navigation .navbar-logo .logo-light {
  display: block;
}

@media (width <= 991.98px) {
  .navigation .navbar-logo .logo-light {
    display: none;
  }
}

.navigation .navbar-nav .nav-item .nav-link {
  color: #fff9;
  padding: 6px 16px;
  font-weight: 500;
  line-height: 58px;
  transition: all .4s;
}

@media (width <= 991.98px) {
  .navigation .navbar-nav .nav-item .nav-link {
    color: var(--bs-header-item-color);
  }
}

.navigation .navbar-nav .nav-item .nav-link.active, .navigation .navbar-nav .nav-item .nav-link:hover {
  color: #ffffffe6;
}

@media (width <= 991.98px) {
  .navigation .navbar-nav .nav-item .nav-link.active, .navigation .navbar-nav .nav-item .nav-link:hover {
    color: #556ee6;
  }

  .navigation .navbar-nav .nav-item .nav-link {
    line-height: 28px !important;
  }
}

.navigation.nav-sticky {
  background-color: var(--bs-topnav-bg);
  box-shadow: 0 .75rem 1.5rem #12263f08;
}

.navigation.nav-sticky .navbar-logo {
  line-height: 60px;
}

.navigation.nav-sticky .navbar-logo .logo-dark {
  display: var(--bs-display-block);
}

.navigation.nav-sticky .navbar-logo .logo-light {
  display: var(--bs-display-none);
}

.navigation.nav-sticky .navbar-nav .nav-item .nav-link {
  color: var(--bs-header-item-color);
  line-height: 48px;
}

.navigation.nav-sticky .navbar-nav .nav-item .nav-link.active, .navigation.nav-sticky .navbar-nav .nav-item .nav-link:hover {
  color: #556ee6;
}

.bg-overlay {
  opacity: .7;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.hero-section {
  padding-top: 220px;
  padding-bottom: 190px;
}

.hero-section.bg-ico-hero {
  background-image: url("bg-ico-hero.10717d21.jpg");
  background-position: top;
  background-size: cover;
}

@media (width <= 575.98px) {
  .hero-section {
    padding-top: 140px;
    padding-bottom: 80px;
  }
}

.hero-section .hero-title {
  font-size: 42px;
}

@media (width <= 575.98px) {
  .hero-section .hero-title {
    font-size: 26px;
  }
}

.hero-section .ico-countdown {
  margin-left: -12px;
  margin-right: -12px;
  font-size: 22px;
}

@media (width <= 575.98px) {
  .hero-section .ico-countdown {
    display: block;
  }
}

.hero-section .ico-countdown .coming-box {
  border: 1px solid var(--bs-border-color);
  background-color: var(--bs-secondary-bg);
  border-radius: 4px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 8px;
}

@media (width <= 575.98px) {
  .hero-section .ico-countdown .coming-box {
    width: 40%;
    margin-bottom: 24px;
    display: inline-block;
  }
}

.hero-section .ico-countdown .coming-box span {
  background-color: var(--bs-light);
  margin-top: 8px;
  padding: 4px;
  font-size: 12px;
}

.hero-section .softcap-progress, .hero-section .softcap-progress .progress-bar {
  overflow: visible;
}

.hero-section .softcap-progress .progress-label {
  text-align: right;
  color: var(--bs-body-color);
  font-size: 12px;
  font-weight: 500;
  position: relative;
  bottom: 20px;
}

.currency-price {
  position: relative;
  bottom: 40px;
}

.client-images img {
  opacity: .7;
  max-height: 34px;
  margin: 12px auto;
  transition: all .4s;
  width: auto !important;
}

.features-number {
  opacity: .1;
}

.team-box .team-social-links a {
  color: var(--bs-body-color);
  font-size: 14px;
}

.blog-box .blog-badge {
  position: absolute;
  top: 12px;
  right: 12px;
}

.landing-footer {
  color: #ffffff80;
  background-color: #2a3042;
  padding: 80px 0 40px;
}

.landing-footer .footer-list-title {
  color: #ffffffe6;
}

.landing-footer .footer-list-menu li a {
  color: #ffffff80;
  margin-bottom: 14px;
  transition: all .4s;
  display: block;
}

.landing-footer .footer-list-menu li a:hover {
  color: #fffc;
}

.landing-footer .blog-post .post {
  color: #ffffff80;
  border-bottom: 1px solid #ffffff1a;
  padding: 16px 0;
  display: block;
}

.landing-footer .blog-post .post .post-title {
  color: #fffc;
  font-size: 14px;
}

.landing-footer .blog-post .post:first-of-type {
  padding-top: 0;
}

.landing-footer .blog-post .post:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

.landing-footer .footer-border {
  border-color: #ffffff1a;
}

.counter-number {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  display: flex;
}

.counter-number span {
  padding-top: 5px;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.coming-box {
  width: 25%;
}

.hori-timeline .events .event-list {
  text-align: center;
  display: block;
}

.hori-timeline .events .event-list .event-down-icon {
  position: relative;
}

.hori-timeline .events .event-list .event-down-icon:before {
  content: "";
  border-bottom: 3px dashed var(--bs-border-color);
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
}

.hori-timeline .events .event-list .event-down-icon .down-arrow-icon {
  background-color: var(--bs-secondary-bg);
  padding: 4px;
  position: relative;
}

.hori-timeline .events .event-list:hover .down-arrow-icon, .hori-timeline .events .event-list.active .down-arrow-icon {
  animation: 1.5s linear infinite fade-down;
}

.hori-timeline .events .event-list.active .down-arrow-icon:before {
  content: "";
}

.verti-timeline {
  border-left: 3px dashed var(--bs-border-color);
  margin: 0 10px;
}

.verti-timeline .event-list {
  padding: 0 0 40px 30px;
  position: relative;
}

.verti-timeline .event-list .event-timeline-dot {
  z-index: 9;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: -9px;
}

.verti-timeline .event-list .event-content {
  border: 2px solid var(--bs-border-color);
  border-radius: 7px;
  position: relative;
}

.verti-timeline .event-list.active .event-timeline-dot {
  color: #556ee6;
}

.verti-timeline .event-list:last-child {
  padding-bottom: 0;
}

.plan-box .plan-btn {
  position: relative;
}

.plan-box .plan-btn:before {
  content: "";
  background: var(--bs-border-color);
  width: 100%;
  height: 2px;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
}

.blog-play-icon {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.jobs-categories a {
  color: var(--bs-body-color);
  transition: all .5s;
}

.jobs-categories a:hover {
  color: #556ee6;
}
/*# sourceMappingURL=auth-email-verification.57e22735.css.map */
